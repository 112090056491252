import { FLAGS_CLIENT_NAME } from "clients/flags";
import useSWR from "swr";
import useClient from "./useClient";

/**
 * Uses the FlagsClient to get all flags and caches them using SWR.
 * @returns {VisibilityFlag[]} flags - VisibilityFlag[]
 * @returns {boolean} isLoading
 * @returns {boolean} isError
 */
const useFlags = () => {
  const flagClient = useClient(FLAGS_CLIENT_NAME);
  const { data, error } = useSWR("getAllFlags", ([_key]) =>
    flagClient.getAll(),
  );

  return {
    flags: data?.flags,
    isLoading: !data && !error,
    isError: error,
  };
};

export default useFlags;
