import { ConfigurationOptions } from "../options";

export const Configuration: ConfigurationOptions = {
  ENVIRONMENT: "prod",
  AWS: {
    CONFIG_BUCKET: "hellomoon-prod-config",
    DYNAMO: {
      TEAMS_TABLE_NAME: "prod-Teams",
      TEAM_MEMBERS_TABLE_NAME: "prod-TeamMembers",
      INVITATIONS_TABLE_NAME: "prod-Invitations",
    },
    ELASTICACHE_MEMCACHED_NODES: [
      "chalice-cache.ojticy.0001.use2.cache.amazonaws.com:11211",
      "chalice-cache.ojticy.0002.use2.cache.amazonaws.com:11211",
      "chalice-cache.ojticy.0003.use2.cache.amazonaws.com:11211",
      "chalice-cache.ojticy.0004.use2.cache.amazonaws.com:11211",
    ],
    REDIS_CLUSTERS: [
      "redis://prod-candlestick-redis-cluster-0001-001.ojticy.0001.use2.cache.amazonaws.com:6379",
      "redis://prod-candlestick-redis-cluster-0001-002.ojticy.0001.use2.cache.amazonaws.com:6379",
      "redis://prod-candlestick-redis-cluster-0001-003.ojticy.0001.use2.cache.amazonaws.com:6379",
    ],
    REDIS_MODE: "",
  },
  CACHE: {
    EXCLUSIONS: "flags$tradingView$latest",
    FORCE_TIMEOUT_AFTER_MS: 1000,
    LIFETIME_SECONDS: 15,
  },
  DISCORD: {
    CLIENT_ID: "1106300500195954838",
    REDIRECT_URI: "/connect/discord/callback",
    ROVE_DISCORD_EVENTS_QUEUE_URL:
      "https://sqs.us-east-2.amazonaws.com/601699586334/prod-RoveDiscordRoleEventsQueue",
  },
  NEXT_PUBLIC: {
    API_URL: "https://brew.hellomoon.io",
    BROWSER_ENV: "prod",
    COGNITO_USER_POOL: "us-east-2_qoZfRM8ZD",
    COGNITO_USER_POOL_ID: "3mtu96fcgunm1v8vfhsb94c6hq",
    README_API_URI: "https://readme-api-key.hellomoon.io",
    RECAPTCHA_KEY: "6Lc2gOkjAAAAAA5YoqcVPMswACZUp7_nd3MEwhZJ",
    SENTRY_DSN:
      "https://0986ab5f34744cf599795c6aecbef0eb@o1378324.ingest.sentry.io/6690114",
  },
  SITE: {
    CORS_ORIGIN: "https://www.hellomoon.io",
    RPC_ENDPOINT: "",
    SKIP_GET_STATIC_PATHS: false,
  },
  STRIPE: {
    PUBLISHABLE_KEY: "",
    PRODUCTS: [
      "prod_NCSGO41Ju7zqbX",
      "prod_NCRsWEec1fD6iY",
      "prod_NCRqapKQdo3Unj",
    ],
    BUSINESS: {
      ANNUAL: "price_1MS2x3D0KT7zVwoYl23WIRmy",
      METERED: "price_1MS2x3D0KT7zVwoYvRKgkgvx",
      MONTHLY: "price_1MS2x3D0KT7zVwoYiap0JC9e",
    },
    DEVELOPER: {
      ANNUAL: "price_1MS2zBD0KT7zVwoYLNwj7Rn2",
      METERED: "price_1MS2zBD0KT7zVwoYb2rCL4qa",
      MONTHLY: "price_1MS2zBD0KT7zVwoY5J0dSGF4",
    },
    FREE: {
      ANNUAL: "price_1MS2zBD0KT7zVwoYLNwj7Rn2",
      METERED: "price_1MS2zBD0KT7zVwoYb2rCL4qa",
      MONTHLY: "price_1MS2zBD0KT7zVwoY5J0dSGF4",
    },
  },
};
