import { FlagNames } from "components/FlagGate";
import { VisibilityFlag } from "model/flags/types";
import useSWR from "swr";
import useClient from "./useClient";

/**
 * SWR-based hook which resolves the state of a feature flag.
 *
 * @returns `undefined` if the flag is not registered in DynamoDB, or the state
 * of the flag stored by DynamoDB.
 */
export function useFeatureFlag(
  flagName: FlagNames,
): VisibilityFlag | undefined {
  const flagClient = useClient("flags");

  const flag = useSWR(`useFeatureFlag-${flagName}`, () => {
    return flagClient.getByFlagName(flagName).then((flag) => {
      return flag?.flag;
    });
  });

  return flag.data;
}
