export enum FlagNames {
  API_NAV_LINKS = "apiNavLinks",
  API_REGISTRATION = "apiRegistration",
  DASHBOARD_RPC_BANNER = "dashboardRpcBanner",
  TOKEN_TRADING_VIEW = "tokenTradingView",
  SHOW_MY_WALLET = "showMyWallet",
  SOCIAL_LINK_INACTIVE = "socialLinkInactive",
  SOCIAL_LINK_ACTIVE = "socialLinkActive",
  NEWS_LINK_ACTIVE = "newsLinkActive",
  SHOW_TOKEN_COMMUNITY_TOKEN_WEALTH = "showCommunityTokenWealth",
  SHOW_TOKEN_PRICE_CHART_WITH_CALLOUTS = "showTokenPriceChartWithCallouts",
  SHOW_PROTOCOL_VOLUME_CHART_WITH_CALLOUTS = "showProtocolVolumeChartWithCallouts",
  SHOW_PROTOCOL_ACTIVE_USER_CHART = "showProtocolActiveUserChart",
  SHOW_PROTOCOL_NEW_USER_CHART = "showProtocolNewUserChart",
  SHOW_TOKEN_ACTIVE_USER_CHART = "showTokenActiveUserChart",
  SHOW_TOKEN_NEW_USER_CHART = "showTokenNewUserChart",
  SHOW_TOP_PROTOCOL_FOR_TOKEN = "showTopProtocolForToken",
  SHOW_TOP_TOKEN_FOR_PROTOCOL = "showTopTokenForProtocol",
  SHOW_PROTOCOL_HR_24_VOLUME_BY_WALLET_SIZE_USD = "showProtocolHr24VolumeByWalletSizeUSD",
  SHOW_PROTOCOL_ACTIVE_USERS_BY_WALLET_SIZE_USD = "showProtocolActiveUsersByWalletSizeUSD",
  SHOW_TOKEN_ACTIVE_USERS_BY_WALLET_SIZE_USD = "showTokenActiveUsersByWalletSizeUSD",
  SHOW_TOKEN_VOLUME_BY_WALLET_SIZE_USD = "showTokenVolumeByWalletSizeUSD",
  SHOW_TOKEN_TOP_WALLETS_BY_HOLDINGS = "showTokenTopWalletsByHoldings",
  SHOW_PROTOCOL_TOP_WALLETS_BY_HR_24_VOLUME = "showProtocolTopWalletsByHr24Volume",
  TOKEN_PAGE_V2 = "tokenPageV2",
  PROTOCOL_PAGE_V2 = "protocolPageV2",
  TOKEN_COLLECTION_SOCIAL = "tokenCollectionSocial",
  PROTOCOL_COLLECTION_SOCIAL = "protocolCollectionSocial",
  SHOW_JUPITER_SWAP_TOKEN_DASH = "showJupiterSwapTokenDash",
  REVERSE_JUPITER_SWAP = "reverseJupiterSwaps",
  SHOW_JUPITER_SWAP_DEFI_HOME = "showJupiterSwapDefiHome",
  REVERSE_JUPITER_SWAP_DEFI_HOME = "reverseJupiterSwapDefiHome",

  //   SOLANA
  SOLANA_DASH_BOARD = "solanaDashBoard",
  SOLANA_DAILY_FIRST_SIGNERS_CHART = "solanaDailyFirstSignersChart",
  SOLANA_ECOSYSTEM = "solanaEcosystem",
  SOLANA_UNIQUE_RECEIVERS = "solanaUniqueReceivers",
  SOLANA_AVERAGE_WALLET_BALANCE_RECEIVERS = "solanaAverageWalletBalanceReceivers",
  SOLANA_AVERAGE_AMOUNT_TRANSFERRED = "solanaAverageAmountTransferred",
  SOLANA_UNIQUE_SENDERS = "solanaUniqueSenders",
  SOLANA_AVERAGE_WALLET_BALANCE_SENDERS = "solanaAverageWalletBalanceSenders",
  SOLANA_VOLUME_CHART = "solanaVolumeChart",
  SOLANA_TOTAL_VALUE_MOVED_ON_CHAIN_CHART = "solanaTotalValueMovedOnChainChart",
  SOLANA_DAILY_ACTIVE_USERS_CHART = "solanaDailyActiveUsersChart",
  SOLANA_KEY_STATS_TABLE = "solanaKeyStatsTable",
  SOLANA_LEGACY_CHARTS = "solanaLegacyCharts",
  SOLANA_NEW_CHARTS_AUG_31 = "solanaNewChartsAug31",

  //   NFT
  NFT_COLLECTION_SOCIAL = "nftCollectionSocial",
  NFT_HOME_PAGE = "nftHomePage",
  NFT_MOBILE_LEADERBOARD = "nftMobileLeaderboard",
  NFT_LEADERBOARD_LIST = "nftLeaderboardList",
  NFT_LEADERBOARDS_MINI = "nftLeaderboardsMini",
  NFT_LEADERBOARDS_MINI_V2 = "nftLeaderboardsMiniV2",
  NFT_TRADING_VIEW = "nftTradingView",
  SHOW_COLLECTION_NET_PROFIT = "showCollectionNetProfit",
  SHOW_NFT_FLOOR_PRICE_CHART = "showNftFloorPriceChart",
  SHOW_NFT_FLOOR_PRICE_CHART_WITH_CALLOUTS = "showNftFloorPriceChartWithCallouts",
  SHOW_NFT_STATS = "showNftStats",
  NFT_SEARCH_AND_FILTER_LEADERBOARD = "nftSearchAndFilterLeaderboard",
  NFT_MARKET = "nftMarket",
  NFT_SMART_MINTERS = "nftSmartMinters",
  NFT_MARKETPLACE_TAB = "nftMarketPlaceTab",

  // TOKEN
  TOKEN_LEADERBOARDS_MINI = "tokenLeaderboardsMini",
  TOKEN_LEADERBOARD_SEARCH_AND_FILTER = "tokenLeaderboardSearchAndFilter",

  // TRADE
  TOKEN_RECENT_SWAPS = "tokenRecentSwaps",

  //   SOCIAL
  SOCIAL_LEADERBOARDS = "socialLeaderboards",
  SOCIAL_ACTIVITIES_LIST = "socialActivitiesList",
  SOCIAL_ACTIVITIES_LIST_OLD = "socialActivitiesListOld",
  SOCIAL_LEADERBOARD_MOBILE = "socialLeaderboardsMobile",
  ACTOR_SELECTION_MOBILE = "actorSelectionMobile",
  SOCIAL_AUTO_COMPLETE_MOBILE = "socialAutoCompleteMobile",

  // Developers
  DEVELOPER_PORTAL = "developerPortal",
  DEVELOPERS_PAGE = "developersPage",
  API_USAGE_COMPONENTS = "apiUsageComponents",

  // Teams
  /** If set, the "Team" link will appear in the nav bar. */
  TEAM_PAGE_NAV_LINK = "teamPageNavLink",

  CONNECT_DISCORD = "connectDiscord",
}
