export enum AppRoutes {
  index = "/",
  solana = "/solana",
  solana2 = "/solana2",
  help = "#",
  settings = "/settings",
  legal = "/legal",
  login = "/login",
  signup = "/sign-up",
  forgotPassword = "/forgot-password",
  confirmSubscription = "/confirm-subscription",
  landing = "/home",
  updatePaymentMethod = "/update-payment-method",
  deactive = "#",
  defi = "/solana-defi",
  defi2 = "/solana-defi2",
  nft = "/nft",
  nfts = "/nfts/top-projects",
  nftsSmartMinting = "/nfts/smart-minting",
  nftsBase = "/nfts",
  nft2 = "/nfts2",
  wallet = "/wallet",
  developers = "/developers",
  portfolio = "/portfolio",
  social = "/social",
  swap = "/swap",
  trade = "/trade",
  news = "/news",
  watchlist = "/watchlist",
  datastreams = "/datastreams",
}

export enum DeveloperHomeRoutes {
  DASHBOARD = "/dashboard",
  DATASTREAMS = "/datastreams",
  // DOCUMENTATION = "/documentation",
  ID_SEARCH = "/id",
  DOCUMENTATION = "https://docs.hellomoon.io",
  PARSED_ACCOUNT_LOOKUP = "/lookup/parsed-account-schemas",
}

export enum DefiComponentRoutes {
  defiTokens = `/solana-defi/tokens`,
  defiPrograms = `/solana-defi/programs`,
}

export enum AppNestedRoutes {
  programs = "programs",
  programs2 = "programs2",
  tokens = "tokens",
  tokens2 = "tokens2",
  token = "token",
}

export enum AppSlugs {
  token = ":slug",
}

export class Routes {
  private static _solana = "solana";
  private static _nfts = "nfts";
  private static _solanaDefi = "solana-defi";
  private static _social = "social";
  private static _swap = "swap";
  private static _porfolio = "portfolio";
  private static _watchlist = "watchlist";
  private static _nft = "nft";

  // Developers nested routes
  private static _developers = "developers";
  private static _dashboard = "dashboard";
  private static _datastreams = "datastreams";

  // Solana-Defi nested routes
  private static _tokens = "tokens";
  private static _programs = "programs";
  private static _tokenOverview = "overview";
  private static _tokenOwners = "owners";
  private static _tokenStats = "stats";

  // Nfts nested routes
  private static _nftTopProjects = "top-projects";
  private static _nftSmartMinting = "smart-minting";
  private static _nftEcosystem = "ecosystem";

  // Nft collection tabs
  private static _nftOverview = "overview";
  private static _nftSocial = "social";
  private static _nftOwners = "owners";
  private static _nftWashtrading = "washtrading";
  private static _nftStats = "stats";
  private static _nftMarketplace = "marketplace";

  // Team page tab
  private static _teams = "teams";

  public static get solana(): string {
    return Routes.route(Routes._solana);
  }

  // BEGIN NFT ROUTES
  public static get nfts(): string {
    return Routes.route(Routes._nfts);
  }

  public static get nftTopProjects(): string {
    return Routes.route(Routes._nfts, Routes._nftTopProjects);
  }

  public static get nftSmartMinting(): string {
    return Routes.route(Routes._nfts, Routes._nftSmartMinting);
  }

  public static get nftEcosystem(): string {
    return Routes.route(Routes._nfts, Routes._nftEcosystem);
  }

  // collections
  public static nftOverview(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftOverview);
  }

  public static nftSocial(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftSocial);
  }

  public static nftOwners(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftOwners);
  }

  public static nftWashtrading(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftWashtrading);
  }

  public static nftStats(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftStats);
  }

  public static nftMarketplace(slug: string): string {
    return Routes.route(Routes._nft, slug, Routes._nftMarketplace);
  }
  // END NFT ROUTES

  // BEGIN SOLANA DEFI ROUTES
  public static get solanaDefi(): string {
    return Routes.route(Routes._solanaDefi);
  }

  public static get tokens(): string {
    return Routes.route(Routes._solanaDefi, Routes._tokens);
  }

  public static get programs(): string {
    return Routes.route(Routes._solanaDefi, Routes._programs);
  }

  public static tokenOverview(slug: string): string {
    // Route back to Solana if the slug is the token primitive
    if (slug === "11111111111111111111111111111111") {
      return Routes.solana;
    }

    return Routes.route(
      Routes._solanaDefi,
      Routes._tokens,
      slug,
      Routes._tokenOverview,
    );
  }

  public static tokenOwners(slug: string): string {
    // Route back to Solana if the slug is the token primitive
    if (slug === "11111111111111111111111111111111") {
      return Routes.solana;
    }

    return Routes.route(
      Routes._solanaDefi,
      Routes._tokens,
      slug,
      Routes._tokenOwners,
    );
  }

  public static tokenStats(slug: string): string {
    // Route back to Solana if the slug is the token primitive
    if (slug === "11111111111111111111111111111111") {
      return Routes.solana;
    }

    return Routes.route(
      Routes._solanaDefi,
      Routes._tokens,
      slug,
      Routes._tokenStats,
    );
  }
  // END SOLANA DEFI ROUTES

  public static get social(): string {
    return Routes.route(Routes._social);
  }

  public static get swap(): string {
    return Routes.route(Routes._swap);
  }

  public static get watchlist(): string {
    return Routes.route(Routes._watchlist);
  }

  public static get portfolio(): string {
    return Routes.route(Routes._porfolio);
  }

  public static get developers(): string {
    return Routes.route(Routes._developers);
  }

  public static get dashboard(): string {
    return Routes.route(Routes._dashboard);
  }

  public static get documentation(): string {
    // return Routes.route(Routes._documentation);
    return DeveloperHomeRoutes.DOCUMENTATION;
  }

  public static get teams(): string {
    return Routes.route(Routes._teams);
  }

  private static route(...routes: string[]): string {
    return `/${routes.join("/")}`;
  }
}
