import { FlagsClientImpl } from "clients/flags";
import If from "components/Conditionals/If";
import { VisibilityFlag } from "model/flags/types";
import { useEffect } from "react";
import { useState } from "react";
import useFlags from "src/hooks/useFlags";

export type FlagGateProps = {
  flagName: string;
  children: any;
};

const FlagGate = ({ flagName, children }: FlagGateProps) => {
  const { flags, isLoading, isError } = useFlags();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading && flags) {
      flags.forEach(({ name, value }) => {
        if (name === flagName && value) {
          setVisible(true);
        }
      });
    }
  }, [isLoading, isError, flags, flagName]);

  return <If condition={visible}>{children}</If>;
};

export default FlagGate;
