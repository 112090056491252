import { randomUUID } from "crypto";
import { z } from "zod";
import { Branded } from "../../src/utils/brand";

export type UserId = Branded<typeof _UserIdBrand, string>;
export const UserId = {
  generate: (): UserId => {
    return randomUUID() as UserId;
  },
  SCHEMA: z
    .string()
    .uuid()
    .transform((x): UserId => x as UserId),
} as const;
declare const _UserIdBrand: unique symbol;

export type User = Readonly<{
  id: UserId;
}>;
