import Logo from "src/icons/V2/Logo/HelloMoon.svg";
import Moon from "src/icons/V2/Logo/Moon.svg";
import Search from "src/icons/V2/Utility/Search.svg";
import Menu from "src/icons/V2/Utility/Menu.svg";
import Chevron from "src/icons/V2/Utility/Chevron_Up.svg";
import Close from "src/icons/V2/Utility/Close.svg";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import * as Accordion from "@radix-ui/react-accordion";
import { useWallet } from "@solana/wallet-adapter-react";
import DropdownListItem from "components/Dropdown/DropdownListItem";
import DropdownContainer from "components/Dropdown/DropdownContainer";
import DropdownList from "components/Dropdown/DropdownList";
import NavItem from "./NavItem";
import NavLabel from "./NavLabel";
import NavLink from "./NavLink";
import { AppRoutes, Routes } from "src/routes/appRoutes";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Link from "next/link";
import NavAccordionHeader from "./NavAccordionHeader";
import NavAccordionLink from "./NavAccordionLink";
import GlobalSearch from "components/GlobalSearch/GlobalSearch";
import { CONTACT_US_FORM_LINK } from "components/ContactUs/constants";
import Anchor from "components/Anchor/Anchor";
import { WalletSignInHeader } from "components/Wallet/WalletSignIn/WalletSignInHeader";
import NavAccordionContent from "./NavAccordionContent";
import { useFeatureFlag } from "../../src/hooks/useFeatureFlag";
import { FlagNames } from "../FlagGate";

const nftRoutes = [
  Routes.nftTopProjects,
  Routes.nftSmartMinting,
  Routes.nftEcosystem,
];

const DevelopersMenuItem = ({
  pageLoaded,
  hasWallet,
  locationPathName,
}: {
  pageLoaded: boolean;
  hasWallet: boolean;
  locationPathName: string;
}) => {
  if (pageLoaded && hasWallet) {
    return (
      <NavItem active={locationPathName === Routes.dashboard}>
        <NavLabel>
          <NavLink href={Routes.dashboard}>Dev Dashboard</NavLink>
        </NavLabel>
      </NavItem>
    );
  } else {
    return (
      <NavItem active={locationPathName === Routes.developers}>
        <NavLabel>
          <NavLink href={Routes.developers}>Developers</NavLink>
        </NavLabel>
      </NavItem>
    );
  }
};

const DevelopersMobileMenuItem = ({
  pageLoaded,
  hasWallet,
  clickHandler,
}: {
  pageLoaded: boolean;
  hasWallet: boolean;
  clickHandler: (showMobile: boolean) => void;
}) => {
  if (pageLoaded && hasWallet) {
    return (
      <Link href={Routes.dashboard} passHref>
        <a
          className="block px-4 py-3 text-xl font-semibold cursor-pointer"
          onClick={() => clickHandler(false)}
        >
          Dev Dashboard
        </a>
      </Link>
    );
  } else {
    return (
      <Link href={Routes.developers} passHref>
        <a
          className="block px-4 py-3 text-xl font-semibold cursor-pointer"
          onClick={() => clickHandler(false)}
        >
          Developers
        </a>
      </Link>
    );
  }
};

export default function Nav() {
  const { wallet } = useWallet();
  const router = useRouter();
  const locationPathName = router.asPath.toLowerCase();

  const [activeHover, setActiveHover] = useState<string | undefined>();
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);
  const [mounted, setMounted] = useState(false);
  const isTeamsPageEnabled =
    useFeatureFlag(FlagNames.TEAM_PAGE_NAV_LINK)?.value ?? false;

  useEffect(() => {
    setMounted(true);
  }, []);

  const title = () => {
    if (locationPathName.includes(Routes.nfts)) return "Solana NFTs";
    if (locationPathName === Routes.tokens) return "Solana DeFi Tokens";
    if (locationPathName.includes(Routes.solanaDefi)) return "Solana DeFi";
    if (locationPathName.includes(Routes.dashboard)) return "Dashboard";
    if (locationPathName.includes(Routes.portfolio)) return "My Portfolio";
    if (locationPathName.includes(Routes.social)) return "Social";
    // This is last because `solana-defi` needs to be checked first
    if (locationPathName.includes(Routes.solana)) return "Solana";
    return "Hello Moon";
  };

  return (
    <>
      <div className="border-hmio-black-900 bg-hmio-black h-[67px] px-6 border-0 border-b border-solid w-full fixed z-50 top-0">
        <section className="justify-between hidden w-full xl:inline-flex">
          <section className="flex pt-[22px]">
            <Link href={`${AppRoutes.nfts}`} passHref>
              <Logo className="cursor-pointer" />
            </Link>
            <NavigationMenu.Root>
              <NavigationMenu.List className="inline-flex gap-6 ml-14 text-hmio-white text-sm h-[41px]">
                <NavItem active={locationPathName === Routes.solana}>
                  <NavLabel>
                    <NavLink href={Routes.solana}>Solana</NavLink>
                  </NavLabel>
                </NavItem>
                <NavItem active={nftRoutes.includes(locationPathName)}>
                  <Anchor to={Routes.nftTopProjects}>
                    <NavLabel active={activeHover === Routes.nfts}>
                      NFTs
                    </NavLabel>
                  </Anchor>
                  <NavigationMenu.Content
                    onMouseEnter={() => setActiveHover(Routes.nfts)}
                    onMouseLeave={() => setActiveHover(undefined)}
                    className="relative"
                  >
                    <DropdownContainer className="absolute left-28 w-36 top-[-3px]">
                      <DropdownList
                        className="
                          text-sm
                          [&>:first-child]:first:rounded-t-xl
                          [&>:last-child]:last:rounded-b-xl
                          [&>*]:px-4
                          [&>*]:py-3
                          [&>*]:bg-hmio-black-900
                          text-hmio-white
                          group-[.extend]:[&>:first-child]:rounded-t-none
                        "
                      >
                        <DropdownListItem hoverColor="bg-hmio-black-800">
                          <NavLink href={Routes.nftTopProjects}>
                            Leaderboard
                          </NavLink>
                        </DropdownListItem>
                        <DropdownListItem hoverColor="bg-hmio-black-800">
                          <NavLink href={Routes.nftSmartMinting}>
                            Smart Minting
                          </NavLink>
                        </DropdownListItem>
                        <DropdownListItem hoverColor="bg-hmio-black-800">
                          <NavLink href={Routes.nftEcosystem}>
                            Ecosystem
                          </NavLink>
                        </DropdownListItem>
                      </DropdownList>
                    </DropdownContainer>
                  </NavigationMenu.Content>
                </NavItem>
                <NavItem
                  active={
                    locationPathName.includes(Routes.tokens) ||
                    locationPathName.includes(Routes.programs)
                  }
                >
                  <Anchor to={Routes.tokens}>
                    <NavLabel active={activeHover === Routes.solanaDefi}>
                      DeFi
                    </NavLabel>
                  </Anchor>
                  <NavigationMenu.Content
                    onMouseEnter={() => setActiveHover(Routes.solanaDefi)}
                    onMouseLeave={() => setActiveHover(undefined)}
                    className="relative"
                  >
                    <DropdownContainer className="absolute left-[170px] w-28 top-[-3px]">
                      <DropdownList
                        className="
                          text-sm
                          [&>:first-child]:first:rounded-t-xl
                          [&>:last-child]:last:rounded-b-xl
                          [&>*]:px-4
                          [&>*]:py-3
                          [&>*]:bg-hmio-black-900
                          text-hmio-white
                          group-[.extend]:[&>:first-child]:rounded-t-none
                        "
                      >
                        <DropdownListItem hoverColor="bg-hmio-black-800">
                          <NavLink href={Routes.tokens}>Tokens</NavLink>
                        </DropdownListItem>
                        <DropdownListItem hoverColor="bg-hmio-black-800">
                          <NavLink href={Routes.programs}>Protocols</NavLink>
                        </DropdownListItem>
                      </DropdownList>
                    </DropdownContainer>
                  </NavigationMenu.Content>
                </NavItem>
                <NavItem active={locationPathName === Routes.social}>
                  <NavLabel>
                    <NavLink href={Routes.social}>Social</NavLink>
                  </NavLabel>
                </NavItem>
                <NavItem active={locationPathName === Routes.swap}>
                  <NavLabel>
                    <NavLink href={Routes.swap}>Swap</NavLink>
                  </NavLabel>
                </NavItem>
                <NavItem active={locationPathName === Routes.portfolio}>
                  <NavLabel>
                    <NavLink href={Routes.portfolio}>Portfolio</NavLink>
                  </NavLabel>
                </NavItem>
                <DevelopersMenuItem
                  hasWallet={!!wallet}
                  locationPathName={locationPathName}
                  pageLoaded={mounted}
                />
                {isTeamsPageEnabled && (
                  <NavItem active={locationPathName === Routes.teams}>
                    <NavLabel>
                      <NavLink href={Routes.teams}>Team</NavLink>
                    </NavLabel>
                  </NavItem>
                )}
              </NavigationMenu.List>
              <div className="mt-1">
                <NavigationMenu.Viewport />
              </div>
            </NavigationMenu.Root>
          </section>
          <section className="mt-3.5 flex h-10 gap-4 ml-4">
            <GlobalSearch screenType="desktop" />
            <WalletSignInHeader />
          </section>
        </section>

        {/* Mobile nav */}
        <section className="flex justify-between xl:hidden">
          {/* mobile search */}
          <div
            className={
              !showMobileSearch
                ? "hidden"
                : "-translate-x-6 w-full absolute flex bg-hmio-black h-[67px] px-6 border-b-hmio-black-900 border-b border-solid border-0"
            }
          >
            <div
              className="flex items-center"
              role="button"
              onClick={() => setShowMobileSearch(false)}
            >
              <Chevron
                className="-rotate-90 fill-hmio-white"
                height="40"
                width="40"
                viewBox="0 0 24 24"
              />
            </div>
            <GlobalSearch screenType="mobile" />
          </div>
          <div className="flex gap-4 pt-2">
            <figure className="inline-block pt-2">
              <Link href={Routes.nftTopProjects} passHref>
                <a
                  className="cursor-pointer"
                  onClick={() => setShowMobile(false)}
                >
                  <Moon width="32" height="32" viewBox="0 0 24 24" />
                </a>
              </Link>
            </figure>
            <span className="flex flex-col justify-center pt-2 text-base font-semibold select-none text-hmio-white">
              {title()}
            </span>
          </div>
          <article className="flex gap-5 pt-4">
            <div role="button" onClick={() => setShowMobileSearch(true)}>
              <Search width="32" height="32" viewBox="0 0 24 24" />
            </div>
            <div role="button" onClick={() => setShowMobile(!showMobile)}>
              {!showMobile ? (
                <Menu
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  className="fill-hmio-white"
                />
              ) : (
                <Close
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  className="fill-hmio-white"
                />
              )}
            </div>
          </article>
        </section>
      </div>
      <section
        className={`${
          !showMobile ? "hidden" : ""
        } w-full h-screen z-10 bg-hmio-black text-white lg:hidden pt-24 sticky top-0`}
      >
        <Link href={Routes.solana} passHref>
          <a
            className="block px-4 py-3 text-xl font-semibold cursor-pointer"
            onClick={() => setShowMobile(false)}
          >
            Solana
          </a>
        </Link>
        <Accordion.Root type="single" collapsible>
          <Accordion.Item value="nfts">
            <NavAccordionHeader className="px-4 py-3 text-xl font-semibold select-none">
              NFTs
            </NavAccordionHeader>
            <NavAccordionContent>
              <ul className="bg-hmio-black-900">
                <li>
                  <NavAccordionLink
                    href={Routes.nftTopProjects}
                    onClick={() => setShowMobile(false)}
                  >
                    Leaderboard
                  </NavAccordionLink>
                </li>
                {/* TODO: Temp disabled until Mobile UI implemented for this */}
                {/*
                <li>
                  <NavAccordionLink
                    href={Routes.nftSmartMinting}
                    onClick={() => setShowMobile(false)}
                  >
                    Smart Minting
                  </NavAccordionLink>
                </li>
                */}
                <li>
                  <NavAccordionLink
                    href={Routes.nftEcosystem}
                    onClick={() => setShowMobile(false)}
                  >
                    Ecosystem
                  </NavAccordionLink>
                </li>
              </ul>
            </NavAccordionContent>
          </Accordion.Item>
          <Accordion.Item value="defi">
            <NavAccordionHeader className="px-4 py-3 text-xl font-semibold">
              DeFi
            </NavAccordionHeader>
            <NavAccordionContent>
              <ul className="bg-hmio-black-900">
                <li>
                  <NavAccordionLink
                    href={Routes.tokens}
                    onClick={() => setShowMobile(false)}
                  >
                    Tokens
                  </NavAccordionLink>
                </li>
                <li>
                  <NavAccordionLink
                    href={Routes.programs}
                    onClick={() => setShowMobile(false)}
                  >
                    Programs
                  </NavAccordionLink>
                </li>
              </ul>
            </NavAccordionContent>
          </Accordion.Item>
        </Accordion.Root>
        <Link href={Routes.social} passHref>
          <a
            className="block px-4 py-3 text-xl font-semibold cursor-pointer"
            onClick={() => setShowMobile(false)}
          >
            Social
          </a>
        </Link>
        <DevelopersMobileMenuItem
          clickHandler={(value: boolean) => {
            setShowMobile(value);
          }}
          hasWallet={!!wallet}
          pageLoaded={mounted}
        />
        {isTeamsPageEnabled && (
          <Link href={Routes.teams} passHref>
            <a
              className="block px-4 py-3 text-xl font-semibold cursor-pointer"
              onClick={() => setShowMobile(false)}
            >
              Team
            </a>
          </Link>
        )}
        <Link href={CONTACT_US_FORM_LINK} passHref>
          <a
            className="block px-4 py-3 text-xl font-semibold cursor-pointer"
            onClick={() => setShowMobile(false)}
          >
            Contact Us
          </a>
        </Link>
      </section>
      {/* temp spacing for mobile */}
      <div className="lg:hidden h-[88px]" />
    </>
  );
}
