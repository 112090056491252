import enhancedAxios from "clients/requestUtil";
import { PricingPlan } from "components/Leaderboard/Developer/PricingPlan/PricingPlanOpts";
import useSWR from "swr";

/** Get the pricing plan features, name, and product_id */
export default function usePricingPlans() {
  const { data, error } = useSWR(["/api/payments/products"], async ([url]) => {
    const { data } = await enhancedAxios.get<PricingPlan[]>(url);
    return data.reduce((acc, d) => {
      acc[d.name] = d;
      return acc;
    }, {} as { [key: string]: PricingPlan });
  });
  return { data, error };
}
