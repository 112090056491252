import If from "components/Conditionals/If";
import { VisibilityFlag } from "model/flags/types";
import { useEffect, useState } from "react";

export type SSRFlagGateProps = {
  flagName: string;
  children: any;
  flags: VisibilityFlag[];
};

const flagIsActive = (flags: VisibilityFlag[], flagName: string) => {
  for (let i = 0; i < flags.length; i++) {
    if (flags[i].name === flagName && flags[i].value) return true;
  }
  return false;
};

/**
 * SSRFlagGate is intended to be used on pages where flags are retrieved
 * as a static or ssr property using getStaticProps() or getServerProps()
 */
const SSRFlagGate = ({ flagName, children, flags }: SSRFlagGateProps) => {
  const [visible, setVisible] = useState(flagIsActive(flags, flagName));

  useEffect(() => {
    if (flagIsActive(flags, flagName)) setVisible(true);
  }, [flagName, flags]);

  return <If condition={visible}>{children}</If>;
};

export default SSRFlagGate;
